import React from 'react';
import '../../App.css';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';
import Footer from "../footer/footer";
import './home.scss';

class Home extends React.Component {
  render() {
    return (
      
      <>
      <div className="main-container boxPage homePage">      
      <video id="myVideo" loop muted autoPlay playsInline>
 <source src={require('../../assets/img/bgAnimation.mp4')} type="video/mp4" />
</video>
        <Header history={this.props.history}/>
          
        <Grid container >
       
          <Grid item sm={12} md={12} className="home" >
        <div>
            <h3>Gordon Haskett</h3>
            <h3>Jet Tracker</h3>
            <br/>
            <p className="desc">Enhance Your Investment Mosaic by Analyzing Corporate Jet Movements <br/>Track Possible Signposts of M&A, Strategic Partnerships, Activism, etc.</p>
       <p>  <Link to={'/login'}>CLIENT LOGIN</Link> &nbsp;&nbsp;&nbsp; <Link to={'/register'}>REQUEST TRIAL ACCESS</Link></p>
       
        </div>
          </Grid>
        </Grid>
        <p className="bottomLink"><a href='https://gordonhaskett.com/research/pc/edd' target='_blank'>GORDON HASKETT EVENT-DRIVEN RESEARCH</a></p>
      </div>
      <Footer 
         linkSection=""/>
      </>
    );
  }
}

export default Home;